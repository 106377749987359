import { Field, Form, Formik, FormikProps } from "formik";
import { FC, ReactNode, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Input } from "../../common/components/control/InputField";
import Modal from "../../common/components/Modal";
import { BaseResponseModel } from "../../common/models/response/ResponseModel";
import { ISetupMemberCommsPlanBUis } from "../models/response";
import * as actions from "../redux/CommissionSettingAction";

export const CommissionSettingEditModal: FC<{
  setCommissionSettingData: React.Dispatch<
    React.SetStateAction<ISetupMemberCommsPlanBUis | undefined>
  >;
  fetchCommissionSettings: () => void;
  commissionSettingData?: ISetupMemberCommsPlanBUis;
}> = ({
  setCommissionSettingData,
  fetchCommissionSettings,
  commissionSettingData,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | ReactNode>(
    ""
  );
  const [modalTitle, setModalTitle] = useState("info");

  const commissionSettingFormikRef = useRef<FormikProps<{
    rebate: string;
    level1comms: string;
    level2comms: string;
    level3comms: string;
  }> | null>(null);

  const commissionSettingSchema = Yup.object().shape({
    rebate: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    level1comms: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    level2comms: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    level3comms: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const setupMemberCommissionPlanB = (values: {
    rebate: string;
    level1comms: string;
    level2comms: string;
    level3comms: string;
  }) => {
    if (commissionSettingData)
      dispatch<any>(
        actions.setupMemberCommissionPlanB({
          action: "setupmembercommsplanB",
          rebate: values.rebate,
          level1comms: values.level1comms,
          level2comms: values.level2comms,
          level3comms: values.level3comms,
          bettype: commissionSettingData.bettype,
        })
      )
        .then((res?: BaseResponseModel) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0"
              ? intl.formatMessage({ id: "saveSuccessfully" })
              : res?.message
              ? res.message
              : intl.formatMessage({ id: "saveFail" })
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            commissionSettingFormikRef.current?.resetForm();
            setCommissionSettingData(undefined);
            fetchCommissionSettings();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "saveFail" }));
          setModalTitle("alert");
        });
  };

  return (
    <>
      <Modal
        style={{ zIndex: "999999" }}
        isOpen={isResponseModalOpen}
        onClose={() => {
          setIsResponseModalOpen(false);
        }}
        title={intl.formatMessage({ id: modalTitle })}
      >
        {responseMessage}
      </Modal>
      <Modal
        isOpen={Boolean(commissionSettingData)}
        onClose={() => {
          setCommissionSettingData(undefined);
        }}
        title={intl.formatMessage(
          { id: "editCommissionSetting" },
          { X: commissionSettingData?.bettype ?? "" }
        )}
      >
        <Formik
          initialValues={{
            rebate: commissionSettingData?.rebate ?? "",
            level1comms: commissionSettingData?.level1comms ?? "",
            level2comms: commissionSettingData?.level2comms ?? "",
            level3comms: commissionSettingData?.level3comms ?? "",
          }}
          innerRef={commissionSettingFormikRef}
          validationSchema={commissionSettingSchema}
          enableReinitialize={true}
          onSubmit={(values) => setupMemberCommissionPlanB(values)}
        >
          {({ values, setFieldValue }) => (
            <Form className="pt-4">
              <div className="mb-3">
                <Field
                  name="rebate"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "rebate",
                  })}
                  label={intl.formatMessage({
                    id: "rebate",
                  })}
                  autoComplete="off"
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text")
                      ?.replace(/\D/g, "");
                    setFieldValue("rebate", pastedText);
                  }}
                  type="numberString"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="level1comms"
                  component={Input}
                  placeholder={`${intl.formatMessage({
                    id: "level",
                  })} 1 ${intl.formatMessage({
                    id: "commission",
                  })}`}
                  label={`${intl.formatMessage({
                    id: "level",
                  })} 1 ${intl.formatMessage({
                    id: "commission",
                  })}`}
                  autoComplete="off"
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text")
                      ?.replace(/\D/g, "");
                    setFieldValue("level1comms", pastedText);
                  }}
                  type="numberString"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="level2comms"
                  component={Input}
                  placeholder={`${intl.formatMessage({
                    id: "level",
                  })} 2 ${intl.formatMessage({
                    id: "commission",
                  })}`}
                  label={`${intl.formatMessage({
                    id: "level",
                  })} 2 ${intl.formatMessage({
                    id: "commission",
                  })}`}
                  autoComplete="off"
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text")
                      ?.replace(/\D/g, "");
                    setFieldValue("level2comms", pastedText);
                  }}
                  type="numberString"
                />
              </div>
              <div className="mb-6">
                <Field
                  name="level3comms"
                  component={Input}
                  placeholder={`${intl.formatMessage({
                    id: "level",
                  })} 3 ${intl.formatMessage({
                    id: "commission",
                  })}`}
                  label={`${intl.formatMessage({
                    id: "level",
                  })} 3 ${intl.formatMessage({
                    id: "commission",
                  })}`}
                  autoComplete="off"
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text")
                      ?.replace(/\D/g, "");
                    setFieldValue("level3comms", pastedText);
                  }}
                  type="numberString"
                />
              </div>
              <button
                className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                type="submit"
              >
                {intl.formatMessage({ id: "save" })}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
