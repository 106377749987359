import { CiSettings } from "react-icons/ci";
import { FaPeopleGroup, FaPeopleRoof } from "react-icons/fa6";
import { FiHome } from "react-icons/fi";
import { TbReportAnalytics, TbReportMoney } from "react-icons/tb";

export const NAVBAR = [
  {
    title: "home",
    icon: <FiHome className="mr-2 size-6" />,
  },
  {
    title: "agent",
    icon: <FaPeopleRoof className="mr-2 size-6" />,
  },
  {
    title: "member",
    icon: <FaPeopleGroup className="mr-2 size-6" />,
  },
  {
    title: "report",
    icon: <TbReportAnalytics className="mr-2 size-6" />,
  },
  {
    title: "transactionRecord",
    icon: <TbReportMoney className="mr-2 size-6" />,
  },
  {
    title: "commissionSetting",
    icon: <CiSettings className="mr-2 size-6" />,
  },
];
