import { IGeneralRedux } from "../../../common/models/response";
import { ICommissionSettingListResponse } from "../response";

export interface ICommissionSettingReduxState extends IGeneralRedux {
  commissionSettingList: ICommissionSettingListResponse | null;
}

export const commissionSettingState: ICommissionSettingReduxState = {
  commissionSettingList: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
