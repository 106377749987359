import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import { DateRangeField } from "../../common/components/control/InputField";
import { Loading } from "../../common/components/Loading";
import * as actions from "../redux/ReportAction";

export const Report = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { reports } = useSelector((state: RootState) => state.report);

  const fetchReport = (pagenum?: number) => {
    dispatch<any>(
      actions.fetchReport({
        action: "agentsummaryreport",
        fromdate: format(startDate, "yyyy-MM-dd"),
        todate: format(endDate, "yyyy-MM-dd"),
      })
    );
  };

  useEffect(() => {
    fetchReport();

    return () => {
      dispatch<any>(actions.clearReports());
    };
  }, []);

  const numberClass = (val: string) =>
    parseFloat(val?.replaceAll(",", "") ?? "0") < 0 ? "text-red-400" : "";

  return (
    <>
      <div className="flex-1">
        <div className="relative mb-4">
          <div className="flex rounded bg-blue-950">
            <div className="flex-grow py-2 rounded outline-none cursor-pointer ps-2 bg-blue-950 focus:outline-none">
              <DateRangeField
                onDateChange={(startDate: Date, endDate: Date) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </div>
            <button
              type="button"
              className="px-2 bg-blue-900 rounded"
              onClick={() => fetchReport()}
            >
              {intl.formatMessage({ id: "search" })}
            </button>
          </div>
        </div>
        {reports ? (
          <>
            <div className="p-4 mb-4 text-sm rounded bg-slate-800">
              <div className="flex items-stretch border border-gray-700">
                <div className="flex items-center justify-center w-1/3 p-1 border-r border-gray-700">
                  {intl.formatMessage({ id: "category" })}
                </div>
                <div className="flex flex-col flex-grow">
                  <div className="w-full p-1 text-center border-b border-gray-700">
                    {intl.formatMessage({ id: "company" })}
                  </div>
                  <div className="flex">
                    <div className="w-1/2 p-1 text-center border-r border-gray-700">
                      H.L
                    </div>
                    <div className="w-1/2 p-1 text-center">M.K.T</div>
                  </div>
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "turnover" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.turnoverhl
                  )}`}
                >
                  {reports.companySummary.turnoverhl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.turnovermkt
                  )}`}
                >
                  {reports.companySummary.turnovermkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "commission" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.commissionhl
                  )}`}
                >
                  {reports.companySummary.commissionhl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.commissionmkt
                  )}`}
                >
                  {reports.companySummary.commissionmkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "prize" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.prizehl
                  )}`}
                >
                  {reports.companySummary.prizehl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.prizemkt
                  )}`}
                >
                  {reports.companySummary.prizemkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "settlement" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.settlementhl
                  )}`}
                >
                  {reports.companySummary.settlementhl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.settlementmkt
                  )}`}
                >
                  {reports.companySummary.settlementmkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "profit" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.profithl
                  )}`}
                >
                  {reports.companySummary.profithl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.companySummary.profitmkt
                  )}`}
                >
                  {reports.companySummary.profitmkt}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/3"></div>
                <div className="w-1/3 p-1 border-t-2 border-gray-700 text-end">
                  {intl.formatMessage({ id: "total" })}
                </div>
                <div
                  className={`w-1/3 p-1 border-t-2 border-b-2 border-gray-700 text-end ${numberClass(
                    reports.companySummary.total
                  )}`}
                >
                  {reports.companySummary.total}
                </div>
              </div>
            </div>
            <div className="p-4 mb-2 text-sm rounded bg-slate-800">
              <div className="flex items-stretch border border-gray-700">
                <div className="flex items-center justify-center w-1/3 p-1 border-r border-gray-700">
                  {intl.formatMessage({ id: "category" })}
                </div>
                <div className="flex flex-col flex-grow">
                  <div className="w-full p-1 text-center border-b border-gray-700">
                    {intl.formatMessage({
                      id: "personalGroupingProfitSharing",
                    })}
                  </div>
                  <div className="flex">
                    <div className="w-1/2 p-1 text-center border-r border-gray-700">
                      H.L
                    </div>
                    <div className="w-1/2 p-1 text-center">M.K.T</div>
                  </div>
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "pTurnover" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.personalturnoverhl
                  )}`}
                >
                  {reports.dividendSummary.personalturnoverhl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.personalturnovermkt
                  )}`}
                >
                  {reports.dividendSummary.personalturnovermkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "gTurnover" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.groupturnoverhl
                  )}`}
                >
                  {reports.dividendSummary.groupturnoverhl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.groupturnovermkt
                  )}`}
                >
                  {reports.dividendSummary.groupturnovermkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "settlement" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.settlementhl
                  )}`}
                >
                  {reports.dividendSummary.settlementhl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.settlementmkt
                  )}`}
                >
                  {reports.dividendSummary.settlementmkt}
                </div>
              </div>
              <div className="flex p-1">
                <div className="w-1/3">
                  {intl.formatMessage({ id: "profitSharing" })}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.profithl
                  )}`}
                >
                  {reports.dividendSummary.profithl}
                </div>
                <div
                  className={`w-1/3 text-end ${numberClass(
                    reports.dividendSummary.profitmkt
                  )}`}
                >
                  {reports.dividendSummary.profitmkt}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/3"></div>
                <div className="w-1/3 p-1 border-t-2 border-gray-700 text-end">
                  {intl.formatMessage({ id: "total" })}
                </div>
                <div
                  className={`w-1/3 p-1 border-t-2 border-b-2 border-gray-700 text-end ${numberClass(
                    reports.dividendSummary.total
                  )}`}
                >
                  {reports.dividendSummary.total}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};
