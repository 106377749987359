import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { homeState } from "../models/state/HomeState";
import { getDashboardBottom, getDashboardHeader } from "./HomeCRUD";

export const homeSlice = createSlice({
  name: "home",
  initialState: homeState,
  reducers: {
    clearDashboard: (state) => {
      state.dashboardHeader = homeState.dashboardHeader;
      state.dashboardBottom = homeState.dashboardBottom;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardHeader.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(
        getDashboardHeader.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.dashboardHeader = action.payload;
        }
      )
      .addCase(
        getDashboardHeader.rejected,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.dashboardHeader = homeState.dashboardHeader;
        }
      )
      .addCase(getDashboardBottom.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(
        getDashboardBottom.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.dashboardBottom = action.payload;
        }
      )
      .addCase(
        getDashboardBottom.rejected,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.dashboardBottom = homeState.dashboardBottom;
        }
      );
  },
});
