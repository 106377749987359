import { Field, Form, Formik, FormikProps } from "formik";
import { FC, ReactNode, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getAuth } from "../../../../utils/AuthHelper";
import { Input } from "../../common/components/control/InputField";
import Modal from "../../common/components/Modal";
import { BaseResponseModel } from "../../common/models/response/ResponseModel";
import { IMemberLists } from "../models/response";
import * as actions from "../redux/MemberAction";

export const ConvertMemberEditModal: FC<{
  setAgentData: React.Dispatch<React.SetStateAction<IMemberLists | undefined>>;
  fetchMembers: () => void;
  agentData?: IMemberLists;
}> = ({ setAgentData, fetchMembers, agentData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | ReactNode>(
    ""
  );
  const [modalTitle, setModalTitle] = useState("info");

  const isPlanB = getAuth()?.plancode === "planb";

  const convertMemberEditFormikRef = useRef<FormikProps<{
    nickname: string;
    share: string;
    keepmemberintro: string;
    commissionmkt: string;
    commissionhl: string;
  }> | null>(null);

  const objectSchema = {
    nickname: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    share: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  };

  const convertMemberEditSchema = Yup.object().shape(objectSchema);

  const planBConvertMemberEditSchema = Yup.object().shape({
    ...objectSchema,
    commissionmkt: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    commissionhl: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const switchMemberToAgent = (values: {
    nickname: string;
    share: string;
    keepmemberintro: string;
    commissionmkt: string;
    commissionhl: string;
  }) => {
    if (agentData)
      dispatch<any>(
        actions.switchMemberToAgent({
          action: "convertmembertoagentV2",
          nickname: values.nickname,
          share: values.share,
          keepmemberintro: values.keepmemberintro,
          selmemberid: agentData.memberid,
          commissionmkt: isPlanB ? values.commissionmkt : "0",
          commissionhl: isPlanB ? values.commissionhl : "0",
        })
      )
        .then((res?: BaseResponseModel) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0"
              ? intl.formatMessage({ id: "updateSuccessfully" })
              : res?.message
              ? res.message
              : intl.formatMessage({ id: "updateFail" })
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            convertMemberEditFormikRef.current?.resetForm();
            setAgentData(undefined);
            fetchMembers();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "updateFail" }));
          setModalTitle("alert");
        });
  };

  return (
    <>
      <Modal
        style={{ zIndex: "999999" }}
        isOpen={isResponseModalOpen}
        onClose={() => {
          setIsResponseModalOpen(false);
        }}
        title={intl.formatMessage({ id: modalTitle })}
      >
        {responseMessage}
      </Modal>
      <Modal
        isOpen={Boolean(agentData)}
        onClose={() => {
          setAgentData(undefined);
        }}
        title={intl.formatMessage({ id: "convertToAgent" })}
      >
        <Formik
          initialValues={{
            nickname: agentData?.nickname ?? "",
            share: "",
            keepmemberintro: "false",
            commissionmkt: "",
            commissionhl: "",
          }}
          innerRef={convertMemberEditFormikRef}
          validationSchema={
            isPlanB ? planBConvertMemberEditSchema : convertMemberEditSchema
          }
          enableReinitialize={true}
          onSubmit={(values) => switchMemberToAgent(values)}
        >
          {({ values, setFieldValue }) => (
            <Form className="pt-4">
              <div className="mb-3">
                <Field
                  name="nickname"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "nickname",
                  })}
                  label={intl.formatMessage({
                    id: "nickname",
                  })}
                  autoComplete="off"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="share"
                  component={Input}
                  placeholder={`${intl.formatMessage({
                    id: "share",
                  })}(%)`}
                  label={`${intl.formatMessage({
                    id: "share",
                  })}(%)`}
                  autoComplete="off"
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text")
                      ?.replace(/\D/g, "");
                    setFieldValue("share", pastedText);
                  }}
                  type="numberString"
                />
              </div>
              {isPlanB && (
                <>
                  <div className="mb-3">
                    <Field
                      name="commissionmkt"
                      component={Input}
                      placeholder={`${intl.formatMessage({
                        id: "mktCommission",
                      })}(%)`}
                      label={`${intl.formatMessage({
                        id: "mktCommission",
                      })}(%)`}
                      autoComplete="off"
                      onPaste={(e: any) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData
                          .getData("text")
                          ?.replace(/\D/g, "");
                        setFieldValue("commissionmkt", pastedText);
                      }}
                      type="numberString"
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="commissionhl"
                      component={Input}
                      placeholder={`${intl.formatMessage({
                        id: "hlCommission",
                      })}(%)`}
                      label={`${intl.formatMessage({
                        id: "hlCommission",
                      })}(%)`}
                      autoComplete="off"
                      onPaste={(e: any) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData
                          .getData("text")
                          ?.replace(/\D/g, "");
                        setFieldValue("commissionhl", pastedText);
                      }}
                      type="numberString"
                    />
                  </div>
                </>
              )}
              {/* <div className="flex items-center mb-6">
                <input
                  className="w-5 h-5"
                  type="checkbox"
                  checked={values.keepmemberintro == "true"}
                  onChange={(e) =>
                    setFieldValue(
                      "keepmemberintro",
                      e.target.checked ? "true" : "false"
                    )
                  }
                />
                <div
                  className="ml-2"
                  onClick={() =>
                    setFieldValue(
                      "keepmemberintro",
                      values.keepmemberintro == "true" ? "false" : "true"
                    )
                  }
                >
                  {intl.formatMessage({
                    id: "keepMemberIntro",
                  })}
                </div>
              </div> */}
              <button
                className="w-full px-4 py-2 mt-3 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                type="submit"
              >
                {intl.formatMessage({ id: "convert" })}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
