import { AppDispatch } from "../../../../setup/Store";
import {
  IAgentCreateRequest,
  IAgentListRequest,
  IAgentNicknameUpdateRequest,
  IAgentTopupRequest,
  IShareAgentInfoRequest,
} from "../models/request";
import { IAgentCommissionRequest } from "../models/request/AgentCommissionRequestModel";
import { IAgentShareRequest } from "../models/request/AgentShareRequestModel";
import * as requestApi from "./AgentCRUD";
import { agentSlice } from "./AgentSlice";

const { actions } = agentSlice;

export const clearAgentList = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearAgentList());
};

export const fetchAgentList =
  (data: IAgentListRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getAgentList(data));
  };

export const clearAgentInfo = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearAgentInfo());
};

export const shareAgentInfo =
  (data: IShareAgentInfoRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.shareAgentInfo(data));
  };

export const createNewAgent =
  (data: IAgentCreateRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.createNewAgent(data)).then(
      (res) => res?.payload
    );
  };

export const editAgentNickname =
  (data: IAgentNicknameUpdateRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.editAgentNickname(data)).then(
      (res) => res?.payload
    );
  };

export const agentTopupAgent =
  (data: IAgentTopupRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.agentTopupAgent(data)).then(
      (res) => res?.payload
    );
  };

export const editAgentShare =
  (data: IAgentShareRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.editAgentShare(data)).then(
      (res) => res?.payload
    );
  };

export const editAgentCommission =
  (data: IAgentCommissionRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.editAgentCommission(data)).then(
      (res) => res?.payload
    );
  };

export const fetchAgentShareCommission =
  (data: IShareAgentInfoRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getAgentShareCommission(data));
  };
