import { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import { Loading, OverlayLoading } from "../../common/components/Loading";
import { ISetupMemberCommsPlanBUis } from "../models/response";
import * as actions from "../redux/CommissionSettingAction";
import { CommissionSettingEditModal } from "./CommissionSettingEditModal";

export const CommissionSetting = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [commissionSettingData, setCommissionSettingData] = useState<
    ISetupMemberCommsPlanBUis | undefined
  >();

  const { commissionSettingList, actionsLoading } = useSelector(
    (state: RootState) => state.commissionSetting
  );

  const fetchCommissionSettings = () => {
    dispatch<any>(
      actions.fetchMemberCommissionPlanB({
        action: "setupmembercommsplanBui",
      })
    );
  };

  useEffect(() => {
    fetchCommissionSettings();

    return () => {
      dispatch<any>(actions.clearCommissionSettingList());
    };
  }, []);

  return (
    <>
      {actionsLoading && <OverlayLoading />}

      <CommissionSettingEditModal
        setCommissionSettingData={setCommissionSettingData}
        commissionSettingData={commissionSettingData}
        fetchCommissionSettings={() => fetchCommissionSettings()}
      />
      <div className="flex-1">
        {commissionSettingList ? (
          <>
            <div className="flex mb-3">
              <div className="px-3 py-2 text-sm rounded me-2 bg-slate-800">
                {intl.formatMessage({ id: "mktCommission" })} :{" "}
                {commissionSettingList.commissionmkt}
              </div>
              <div className="px-3 py-2 text-sm rounded bg-slate-800">
                {intl.formatMessage({ id: "hlCommission" })} :{" "}
                {commissionSettingList.commissionhl}
              </div>
            </div>
            {commissionSettingList.setupMemberCommsPlanBUis?.length > 0 ? (
              <div className="overflow-x-auto rounded">
                <table className="min-w-full">
                  <thead className="bg-slate-800">
                    <tr>
                      <th
                        className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border border-gray-700"
                        rowSpan={2}
                      >
                        {intl.formatMessage({ id: "betType" })}
                      </th>
                      <th
                        className="p-2 text-sm font-semibold tracking-wider text-right text-gray-200 uppercase border border-gray-700"
                        rowSpan={2}
                      >
                        {intl.formatMessage({ id: "rebate" })}
                      </th>
                      <th
                        className="p-2 text-sm font-semibold tracking-wider text-center text-gray-200 uppercase border border-b-0 border-gray-700"
                        colSpan={3}
                      >
                        {intl.formatMessage({ id: "commission" })}
                      </th>
                      <th
                        className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border border-gray-700"
                        rowSpan={2}
                      >
                        {intl.formatMessage({ id: "action" })}
                      </th>
                    </tr>
                    <tr>
                      <th className="p-2 text-sm font-semibold tracking-wider text-right text-gray-200 uppercase border border-gray-700 whitespace-nowrap">
                        {intl.formatMessage({ id: "level" })} 1
                      </th>
                      <th className="p-2 text-sm font-semibold tracking-wider text-right text-gray-200 uppercase border border-gray-700 whitespace-nowrap">
                        {intl.formatMessage({ id: "level" })} 2
                      </th>
                      <th className="p-2 text-sm font-semibold tracking-wider text-right text-gray-200 uppercase border border-gray-700 whitespace-nowrap">
                        {intl.formatMessage({ id: "level" })} 3
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {commissionSettingList.setupMemberCommsPlanBUis.map(
                      (setupMemberCommsPlanBUi, index) => (
                        <tr key={setupMemberCommsPlanBUi.bettype}>
                          <td className="p-2 text-sm border border-t-0 border-gray-700 whitespace-nowrap">
                            {setupMemberCommsPlanBUi.bettype}
                          </td>
                          <td className="p-2 text-sm text-right border border-t-0 border-gray-700">
                            {setupMemberCommsPlanBUi.rebate}
                          </td>
                          <td className="p-2 text-sm text-right border border-t-0 border-gray-700">
                            {setupMemberCommsPlanBUi.level1comms}
                          </td>
                          <td className="p-2 text-sm text-right border border-t-0 border-gray-700">
                            {setupMemberCommsPlanBUi.level2comms}
                          </td>
                          <td className="p-2 text-sm text-right border border-t-0 border-gray-700">
                            {setupMemberCommsPlanBUi.level3comms}
                          </td>
                          <td className="p-2 text-sm border border-t-0 border-gray-700">
                            <div className="flex justify-center space-x-2">
                              <CiEdit
                                className="cursor-pointer size-4"
                                onClick={() =>
                                  setCommissionSettingData(
                                    setupMemberCommsPlanBUi
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center">
                {intl.formatMessage({ id: "noRecordFound" })}
              </div>
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};
