import { createAsyncThunk } from "@reduxjs/toolkit";
import { authPost } from "../../common/redux/ApiClient";
import {
  IAgentCommissionRequest,
  IAgentCreateRequest,
  IAgentListRequest,
  IAgentNicknameUpdateRequest,
  IAgentShareRequest,
  IAgentTopupRequest,
  IShareAgentInfoRequest,
} from "../models/request";

export const getAgentList = createAsyncThunk(
  "sample/agentlist",
  async (data: IAgentListRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);

export const shareAgentInfo = createAsyncThunk(
  "sample/shareagentinfo",
  async (data: IShareAgentInfoRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent2.ashx`, data);
  }
);

export const createNewAgent = createAsyncThunk(
  "sample/createnewagent",
  async (data: IAgentCreateRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);

export const agentTopupAgent = createAsyncThunk(
  "sample/agenttopupagent",
  async (data: IAgentTopupRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent2.ashx`, data);
  }
);

export const editAgentNickname = createAsyncThunk(
  "sample/editagentnickname",
  async (data: IAgentNicknameUpdateRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent2.ashx`, data);
  }
);

export const editAgentShare = createAsyncThunk(
  "sample/editagentshare",
  async (data: IAgentShareRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent4.ashx`, data);
  }
);

export const editAgentCommission = createAsyncThunk(
  "sample/editagentcommission",
  async (data: IAgentCommissionRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent4.ashx`, data);
  }
);

export const getAgentShareCommission = createAsyncThunk(
  "sample/selagentsharecommission",
  async (data: IShareAgentInfoRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent4.ashx`, data);
  }
);
