import { AppDispatch } from "../../../../setup/Store";
import { BaseRequestModel } from "../../common/models/request/RequestModel";
import { ISetupMemberCommissionPlanBRequest } from "../models/request";
import * as requestApi from "./CommissionSettingCRUD";
import { commissionSettingSlice } from "./CommissionSettingSlice";

const { actions } = commissionSettingSlice;

export const clearCommissionSettingList = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearCommissionSettingList());
};

export const fetchMemberCommissionPlanB =
  (data: BaseRequestModel) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getMemberCommissionPlanB(data));
  };

export const setupMemberCommissionPlanB =
  (data: ISetupMemberCommissionPlanBRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.setupMemberCommissionPlanB(data)).then(
      (res) => res?.payload
    );
  };
