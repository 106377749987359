import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseRequestModel } from "../models/request/RequestModel";
import { authPost } from "./ApiClient";

export const getAgentBalance = createAsyncThunk(
  "sample/agentbalance",
  async (data: BaseRequestModel) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);

export const getAgentMemberDetail = createAsyncThunk(
  "sample/agentredirectmember",
  async (data: BaseRequestModel) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);
