import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseRequestModel } from "../../common/models/request/RequestModel";
import { authPost } from "../../common/redux/ApiClient";
import { ISetupMemberCommissionPlanBRequest } from "../models/request";

export const getMemberCommissionPlanB = createAsyncThunk(
  "sample/setupmembercommsplanBui",
  async (data: BaseRequestModel) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent4.ashx`, data);
  }
);

export const setupMemberCommissionPlanB = createAsyncThunk(
  "sample/setupmembercommsplanB",
  async (data: ISetupMemberCommissionPlanBRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent4.ashx`, data);
  }
);
