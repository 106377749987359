// src/routes.tsx
import { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { getAuth } from "../../utils/AuthHelper";
import { IAgentLoginResponse } from "../modules/auth/models/response";
import LayoutProvider from "../modules/common/components/layout/LayoutProvider";
import { AUTH_LOCAL_STORAGE_KEY } from "../modules/common/redux/ApiClient";
import { StatusLoading } from "../modules/statusPage/component/StatusLoading";
import { StatusUIBreak } from "../modules/statusPage/component/StatusUIBreak";
import { AgentWrapper } from "../pages/agent/AgentWrapper";
import { AuthWrapper } from "../pages/auth/AuthWrapper";
import { CommissionSettingWrapper } from "../pages/commissionSetting/CommissionSettingWrapper";
import { HomeWrapper } from "../pages/home/HomeWrapper";
import { MemberWrapper } from "../pages/member/MemberWrapper";
import { ReportWrapper } from "../pages/report/ReportWrapper";
import { SettingWrapper } from "../pages/setting/SettingWrapper";
import { TransactionRecordWrapper } from "../pages/transactionRecord/TransactionRecordWrapper";

const AppRoutes = () => {
  const [auth, setAuth] = useState<IAgentLoginResponse | undefined>(getAuth());

  useEffect(() => {
    const handleAuthEvent = (e: any) => {
      setAuth(e?.detail);
      if (e?.detail) {
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(e.detail));
      } else {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
      }
    };

    window.addEventListener("authEvent", handleAuthEvent);
    return () => window.removeEventListener("authEvent", handleAuthEvent);
  }, []);

  return (
    <Suspense fallback={<StatusLoading />}>
      <ErrorBoundary fallback={<StatusUIBreak />}>
        {!auth || auth?.toactive === "true" ? (
          <div className="p-4">
            <BrowserRouter>
              <Routes>
                <Route index element={<AuthWrapper />} />
                <Route path="/*" element={<AuthWrapper />} />
              </Routes>
            </BrowserRouter>
          </div>
        ) : (
          <BrowserRouter>
            <Routes>
              <Route
                path="/auth/*"
                element={<AuthWrapper isClearAuth={true} />}
              />
              <Route
                path="/*"
                element={
                  <LayoutProvider>
                    <Routes>
                      <Route index element={<Navigate to="/home" />} />
                      <Route path="home/*" element={<HomeWrapper />} />
                      <Route path="agent/*" element={<AgentWrapper />} />
                      <Route path="member/*" element={<MemberWrapper />} />
                      <Route path="report/*" element={<ReportWrapper />} />
                      <Route
                        path="transactionRecord/*"
                        element={<TransactionRecordWrapper />}
                      />
                      <Route path="setting/*" element={<SettingWrapper />} />
                      <Route
                        path="commissionSetting/*"
                        element={<CommissionSettingWrapper />}
                      />

                      {/* Fallback route for undefined paths */}
                      <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                  </LayoutProvider>
                }
              />
            </Routes>
          </BrowserRouter>
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default AppRoutes;
