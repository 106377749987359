import { AppDispatch } from "../../../../setup/Store";
import { BaseRequestModel } from "../../common/models/request/RequestModel";
import { IDashboardBottomRequest } from "../models/request";
import * as requestApi from "./HomeCRUD";
import { homeSlice } from "./HomeSlice";

const { actions } = homeSlice;

export const clearDashboard = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearDashboard());
};

export const fetchDashboardHeader =
  (data: BaseRequestModel) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getDashboardHeader(data));
  };

export const fetchDashboardBottom =
  (data: IDashboardBottomRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getDashboardBottom(data));
  };
