import { AppDispatch } from "../../../../setup/Store";
import { BaseRequestModel } from "../models/request/RequestModel";
import * as requestApi from "./CommonCRUD";

export const fetchAgentBalance =
  (data: BaseRequestModel) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getAgentBalance(data));
  };

export const fetchAgentMemberDetail =
  (data: BaseRequestModel) => (dispatch: AppDispatch) =>
    dispatch(requestApi.getAgentMemberDetail(data)).then((res) => res?.payload);
