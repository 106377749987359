import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { memberState } from "../models/state/MemberState";
import {
  convertMemberToAgent,
  createNewMember,
  editMemberNickname,
  getMemberList,
  shareMemberInfo,
  topupMember,
} from "./MemberCRUD";

export const memberSlice = createSlice({
  name: "member",
  initialState: memberState,
  reducers: {
    clearMemberList: (state) => {
      state.memberList = memberState.memberList;
    },
    clearMemberInfo: (state) => {
      state.memberInfo = memberState.memberInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberList.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getMemberList.fulfilled, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.memberList = action.payload;
      })
      .addCase(getMemberList.rejected, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.memberList = memberState.memberList;
      })
      .addCase(shareMemberInfo.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        shareMemberInfo.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
          state.memberInfo = action.payload;
        }
      )
      .addCase(
        shareMemberInfo.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
          state.memberInfo = memberState.memberInfo;
        }
      )
      .addCase(createNewMember.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        createNewMember.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(
        createNewMember.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(editMemberNickname.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        editMemberNickname.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(
        editMemberNickname.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(topupMember.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(topupMember.fulfilled, (state, action: PayloadAction<any>) => {
        state.actionsLoading = false;
      })
      .addCase(topupMember.rejected, (state, action: PayloadAction<any>) => {
        state.actionsLoading = false;
      })
      .addCase(convertMemberToAgent.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        convertMemberToAgent.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(
        convertMemberToAgent.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      );
  },
});
