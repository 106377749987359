import { Field, Form, Formik, FormikProps } from "formik";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { RootState } from "../../../../setup/RootReducer";
import { getAuth } from "../../../../utils/AuthHelper";
import { EditOptionEnum } from "../../../enumeration/EditOptionEnum";
import { Input } from "../../common/components/control/InputField";
import Modal from "../../common/components/Modal";
import { BaseResponseModel } from "../../common/models/response/ResponseModel";
import * as commonActions from "../../common/redux/CommonAction";
import { IAgentLists } from "../models/response";
import { IAgentTopupResponse } from "../models/response/AgentTopupResponseModel";
import * as actions from "../redux/AgentAction";

export const AgentEditModal: FC<{
  setAgentData: React.Dispatch<React.SetStateAction<IAgentLists | undefined>>;
  fetchAgents: () => void;
  agentData?: IAgentLists;
}> = ({ setAgentData, fetchAgents, agentData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | ReactNode>(
    ""
  );
  const [modalTitle, setModalTitle] = useState("info");
  const [agentEditOption, setAgentEditOption] = useState(
    EditOptionEnum.Transaction
  );

  const { agentShareCommission } = useSelector(
    (state: RootState) => state.agent
  );

  const isPlanB = getAuth()?.plancode === "planb";

  const nicknameEditFormikRef = useRef<FormikProps<{
    nickname: string;
  }> | null>(null);

  const topupFormikRef = useRef<FormikProps<{
    amount: string;
  }> | null>(null);

  const shareFormikRef = useRef<FormikProps<{
    share: string;
  }> | null>(null);

  const commissionFormikRef = useRef<FormikProps<{
    commissionmkt: string;
    commissionhl: string;
  }> | null>(null);

  const nicknameEditSchema = Yup.object().shape({
    nickname: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const topupSchema = Yup.object().shape({
    amount: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const shareSchema = Yup.object().shape({
    share: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const commissionSchema = Yup.object().shape({
    commissionmkt: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    commissionhl: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const handleSelectionClick = (agentEditOption: EditOptionEnum) => {
    setAgentEditOption(agentEditOption);
  };

  const editAgentNickname = (values: { nickname: string }) => {
    if (agentData)
      dispatch<any>(
        actions.editAgentNickname({
          action: "editagentnickname",
          nickname: values.nickname,
          selagentid: agentData.agentid,
        })
      )
        .then((res?: BaseResponseModel) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0"
              ? intl.formatMessage({ id: "updateSuccessfully" })
              : res?.message
              ? res.message
              : intl.formatMessage({ id: "updateFail" })
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            nicknameEditFormikRef.current?.resetForm();
            setAgentData(undefined);
            fetchAgents();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "updateFail" }));
          setModalTitle("alert");
        });
  };

  const editAgentAmount = (values: { amount: string }) => {
    if (agentData)
      dispatch<any>(
        actions.agentTopupAgent({
          action: "agenttopupagent",
          amount: values.amount,
          selagentid: agentData.agentid,
        })
      )
        .then((res?: IAgentTopupResponse) => {
          dispatch<any>(
            commonActions.fetchAgentBalance({ action: "agentbalance" })
          );
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0" ? (
              <>
                <div className="mb-3">
                  {intl.formatMessage({ id: "topupSuccessfully" })}
                </div>
                <div>
                  {intl.formatMessage({ id: "remainingBalance" })} :{" "}
                  {res?.balance ?? "0.00"}
                </div>
              </>
            ) : res?.message ? (
              res.message
            ) : (
              intl.formatMessage({ id: "topupFail" })
            )
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            topupFormikRef.current?.resetForm();
            setAgentData(undefined);
            fetchAgents();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "topupFail" }));
          setModalTitle("alert");
        });
  };

  const editAgentShare = (values: { share: string }) => {
    if (agentData)
      dispatch<any>(
        actions.editAgentShare({
          action: "editagentshare",
          share: values.share,
          selagentid: agentData.agentid,
        })
      )
        .then((res?: BaseResponseModel) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0"
              ? intl.formatMessage({ id: "updateSuccessfully" })
              : res?.message
              ? res.message
              : intl.formatMessage({ id: "updateFail" })
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            shareFormikRef.current?.resetForm();
            setAgentData(undefined);
            fetchAgents();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "updateFail" }));
          setModalTitle("alert");
        });
  };

  const editAgentCommission = (values: {
    commissionmkt: string;
    commissionhl: string;
  }) => {
    if (agentData)
      dispatch<any>(
        actions.editAgentCommission({
          action: "editagentcommission",
          commissionmkt: values.commissionmkt,
          commissionhl: values.commissionhl,
          selagentid: agentData.agentid,
        })
      )
        .then((res?: BaseResponseModel) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0"
              ? intl.formatMessage({ id: "updateSuccessfully" })
              : res?.message
              ? res.message
              : intl.formatMessage({ id: "updateFail" })
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            commissionFormikRef.current?.resetForm();
            setAgentData(undefined);
            fetchAgents();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "updateFail" }));
          setModalTitle("alert");
        });
  };

  useEffect(() => {
    if (agentData && isPlanB) {
      dispatch<any>(
        actions.fetchAgentShareCommission({
          action: "selagentsharecommission",
          selagentid: agentData.agentid,
        })
      );
    }
    setAgentEditOption(EditOptionEnum.Transaction);
  }, [agentData]);

  return (
    <>
      <Modal
        style={{ zIndex: "999999" }}
        isOpen={isResponseModalOpen}
        onClose={() => {
          setIsResponseModalOpen(false);
        }}
        title={intl.formatMessage({ id: modalTitle })}
      >
        {responseMessage}
      </Modal>
      <Modal
        isOpen={Boolean(agentData)}
        onClose={() => {
          setAgentData(undefined);
        }}
        title={intl.formatMessage({ id: "editAgent" })}
      >
        <div className="flex border border-gray-300 rounded">
          <div
            className={`flex-1 font-semibold p-2 text-center border-r border-gray-300 cursor-pointer ${
              agentEditOption === EditOptionEnum.Transaction
                ? "bg-yellow-400 text-black"
                : ""
            }`}
            onClick={() => handleSelectionClick(EditOptionEnum.Transaction)}
          >
            {intl.formatMessage({ id: "topup" })}
          </div>
          <div
            className={`flex-1 p-2 text-center cursor-pointer font-semibold border-r border-gray-300  ${
              agentEditOption === EditOptionEnum.Nickname
                ? "bg-yellow-400 text-black"
                : ""
            }`}
            onClick={() => handleSelectionClick(EditOptionEnum.Nickname)}
          >
            {intl.formatMessage({ id: "info" })}
          </div>
          <div
            className={`flex-1 p-2 text-center cursor-pointer font-semibold ${
              isPlanB ? "border-r border-gray-300" : ""
            } ${
              agentEditOption === EditOptionEnum.Share
                ? "bg-yellow-400 text-black"
                : ""
            }`}
            onClick={() => handleSelectionClick(EditOptionEnum.Share)}
          >
            {intl.formatMessage({ id: "share" })}
          </div>
          {isPlanB && (
            <div
              className={`flex-1 p-2 text-center cursor-pointer font-semibold ${
                agentEditOption === EditOptionEnum.Commission
                  ? "bg-yellow-400 text-black"
                  : ""
              }`}
              onClick={() => handleSelectionClick(EditOptionEnum.Commission)}
            >
              {intl.formatMessage({ id: "commission" })}
            </div>
          )}
        </div>
        {agentEditOption === EditOptionEnum.Transaction ? (
          <Formik
            initialValues={{
              amount: "",
            }}
            innerRef={topupFormikRef}
            validationSchema={topupSchema}
            enableReinitialize={true}
            onSubmit={(values) => editAgentAmount(values)}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-6">
                  <Field
                    name="amount"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "amount",
                    })}
                    label={intl.formatMessage({
                      id: "amount",
                    })}
                    autoComplete="off"
                    onPaste={(e: any) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData
                        .getData("text")
                        ?.replace(/\D/g, "");
                      setFieldValue("share", pastedText);
                    }}
                    type="numberString"
                  />
                </div>
                <button
                  className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "topup" })}
                </button>
              </Form>
            )}
          </Formik>
        ) : agentEditOption === EditOptionEnum.Share ? (
          <Formik
            initialValues={{
              share: agentData?.share ?? "0",
            }}
            innerRef={shareFormikRef}
            validationSchema={shareSchema}
            enableReinitialize={true}
            onSubmit={(values) => editAgentShare(values)}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-6">
                  <Field
                    name="share"
                    component={Input}
                    placeholder={`${intl.formatMessage({
                      id: "share",
                    })}(%)`}
                    label={`${intl.formatMessage({
                      id: "share",
                    })}(%)`}
                    autoComplete="off"
                    onPaste={(e: any) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData
                        .getData("text")
                        ?.replace(/\D/g, "");
                      setFieldValue("share", pastedText);
                    }}
                    type="numberString"
                  />
                </div>
                <button
                  className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </Form>
            )}
          </Formik>
        ) : agentEditOption === EditOptionEnum.Commission && isPlanB ? (
          <Formik
            initialValues={{
              commissionmkt: agentShareCommission?.commissionmkt ?? "0",
              commissionhl: agentShareCommission?.commissionhl ?? "0",
            }}
            innerRef={commissionFormikRef}
            validationSchema={commissionSchema}
            enableReinitialize={true}
            onSubmit={(values) => editAgentCommission(values)}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-3">
                  <Field
                    name="commissionmkt"
                    component={Input}
                    placeholder={`${intl.formatMessage({
                      id: "mktCommission",
                    })}(%)`}
                    label={`${intl.formatMessage({
                      id: "mktCommission",
                    })}(%)`}
                    autoComplete="off"
                    onPaste={(e: any) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData
                        .getData("text")
                        ?.replace(/\D/g, "");
                      setFieldValue("commissionmkt", pastedText);
                    }}
                    type="numberString"
                  />
                </div>
                <div className="mb-6">
                  <Field
                    name="commissionhl"
                    component={Input}
                    placeholder={`${intl.formatMessage({
                      id: "hlCommission",
                    })}(%)`}
                    label={`${intl.formatMessage({
                      id: "hlCommission",
                    })}(%)`}
                    autoComplete="off"
                    onPaste={(e: any) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData
                        .getData("text")
                        ?.replace(/\D/g, "");
                      setFieldValue("commissionhl", pastedText);
                    }}
                    type="numberString"
                  />
                </div>
                <button
                  className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={{
              nickname: agentData?.nickname ?? "",
            }}
            innerRef={nicknameEditFormikRef}
            validationSchema={nicknameEditSchema}
            enableReinitialize={true}
            onSubmit={(values) => editAgentNickname(values)}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-6">
                  <Field
                    name="nickname"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "nickname",
                    })}
                    label={intl.formatMessage({
                      id: "nickname",
                    })}
                    autoComplete="off"
                    type="text"
                  />
                </div>
                <button
                  className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};
