import { format } from "date-fns";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { LuRefreshCcw } from "react-icons/lu";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import AlertPopup from "../../common/components/AlertPopup";
import { DateRangeField } from "../../common/components/control/InputField";
import * as actions from "../redux/HomeAction";

export const Home = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isAlertVisible, setAlertVisible] = useState(false);

  const { dashboardHeader, dashboardBottom } = useSelector(
    (state: RootState) => state.home
  );

  const fetchDashboardBottom = () => {
    dispatch<any>(
      actions.fetchDashboardBottom({
        action: "dashboardbottom",
        fromdate: format(startDate, "yyyy-MM-dd"),
        todate: format(endDate, "yyyy-MM-dd"),
      })
    );
  };

  useEffect(() => {
    dispatch<any>(actions.fetchDashboardHeader({ action: "dashboardheader" }));
    fetchDashboardBottom();
  }, []);

  return (
    <>
      <AlertPopup
        isVisible={isAlertVisible}
        message={intl.formatMessage({ id: "copied" })}
      />
      <div className="flex flex-col p-2 space-y-2 text-sm rounded bg-slate-950">
        <div className="flex items-center">
          <div className="flex-1">
            {intl.formatMessage({ id: "username" })} :{" "}
          </div>
          <div className="w-2/4">{dashboardHeader?.username}</div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">{intl.formatMessage({ id: "level" })} :</div>
          <div className="w-2/4">{dashboardHeader?.role}</div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">{intl.formatMessage({ id: "status" })} :</div>
          <div className="w-2/4">{dashboardHeader?.status}</div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">
            {intl.formatMessage({ id: "directGroupAgent" })} :
          </div>
          <div className="w-2/4">
            {dashboardHeader?.agentcount}/{dashboardHeader?.agentgroupcount}
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">
            {intl.formatMessage({ id: "directGroupDownline" })} :
          </div>
          <div className="w-2/4">
            {dashboardHeader?.membercount}/{dashboardHeader?.membergroupcount}
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">
            {intl.formatMessage({ id: "dividendPercentage" })} :
          </div>
          <div className="w-2/4">{dashboardHeader?.share}</div>
        </div>
        {/* <div className="flex items-center">
          <div className="flex-1">
            {intl.formatMessage({ id: "commission" })} :
          </div>
          <div className="w-2/4">{dashboardHeader?.systemsharecoms}</div>
        </div> */}
      </div>
      <div className="flex items-center my-4 space-x-2 text-sm">
        <div className="whitespace-nowrap">
          {intl.formatMessage({ id: "refferalUrl" })}
        </div>
        <Marquee className="flex-grow">
          <div className="pe-4">{dashboardHeader?.inviteurl}</div>
        </Marquee>
        <HiOutlineClipboardCopy
          className="cursor-pointer size-5 me-2"
          onClick={() =>
            navigator.clipboard
              .writeText(dashboardHeader?.inviteurl ?? "")
              .then(() => {
                setAlertVisible(true);
                setTimeout(() => {
                  setAlertVisible(false);
                }, 1500);
              })
          }
        />
      </div>
      <div className="flex items-center my-4 space-x-2 text-sm">
        <div className="whitespace-nowrap">
          {intl.formatMessage({ id: "telegramUrl" })}
        </div>
        <Marquee className="flex-grow">
          <div className="pe-4">{dashboardHeader?.telegraminviteurl}</div>
        </Marquee>
        <HiOutlineClipboardCopy
          className="cursor-pointer size-5 me-2"
          onClick={() =>
            navigator.clipboard
              .writeText(dashboardHeader?.telegraminviteurl ?? "")
              .then(() => {
                setAlertVisible(true);
                setTimeout(() => {
                  setAlertVisible(false);
                }, 1500);
              })
          }
        />
      </div>
      <div className="flex flex-col p-2 text-sm rounded bg-slate-950">
        <div className="flex items-center border-b border-gray-700">
          <div className="flex-grow">
            {intl.formatMessage({ id: "category" })}
          </div>
          <div className="flex flex-col w-2/3 space-y-2">
            <div className="flex w-full pl-2">
              <DateRangeField
                onDateChange={(startDate: Date, endDate: Date) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                wrapperClassName="flex-grow"
                className="text-center"
              />
              <button
                type="button"
                className="px-2 bg-blue-900 rounded"
                onClick={fetchDashboardBottom}
              >
                <LuRefreshCcw />
              </button>
            </div>
            <div className="flex w-full pb-2">
              <div className="w-1/4" />
              <div className="flex w-3/4">
                <div className="w-1/2 font-bold text-right">H.L</div>
                <div className="w-1/2 font-bold text-right">M.K.T</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex border-b border-gray-700">
          <div className="w-1/2 py-2 ">
            {intl.formatMessage({ id: "company" })}/
            {intl.formatMessage({ id: "turnover" })}
          </div>
          <div className="w-1/4 py-2 text-right">
            {dashboardBottom?.hlcompany}
          </div>
          <div className="w-1/4 py-2 text-right">
            {dashboardBottom?.mktcompany}
          </div>
        </div>
        <div className="flex border-b border-gray-700">
          <div className="w-1/2 py-2 ">
            {intl.formatMessage({ id: "personal" })}/
            {intl.formatMessage({ id: "turnover" })}
          </div>
          <div className="w-1/4 py-2 text-right">
            {dashboardBottom?.hlpersonal}
          </div>
          <div className="w-1/4 py-2 text-right">
            {dashboardBottom?.mktpersonal}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 py-2 ">
            {intl.formatMessage({ id: "group" })}/
            {intl.formatMessage({ id: "turnover" })}
          </div>
          <div className="w-1/4 py-2 text-right">
            {dashboardBottom?.hlgroup}
          </div>
          <div className="w-1/4 py-2 text-right">
            {dashboardBottom?.mktgroup}
          </div>
        </div>
      </div>
    </>
  );
};
