import axios from "axios";
import { getAuth } from "../../../../utils/AuthHelper";

export const AUTH_LOCAL_STORAGE_KEY = "UserData";

export const postData = async (url: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${window.location.protocol}//${process.env.REACT_APP_API_DOMAIN}/index.php`,
      {
        url: url,
        data,
      }
    );

    if (response.data?.code == "-99") {
      window.dispatchEvent(new Event("timeout"));
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw new Error("Network error");
    }
  }
};

export const authPost = async (url: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${window.location.protocol}//${process.env.REACT_APP_API_DOMAIN}/index.php`,
      {
        url: url,
        data: {
          ...data,
          agentid: getAuth()?.agentid,
          loginid: getAuth()?.loginid,
        },
      }
    );

    if (response.data?.code == "-99") {
      window.dispatchEvent(new Event("timeout"));
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw new Error("Network error");
    }
  }
};

export const getData = async (url: string, data?: any): Promise<any> => {
  try {
    const getUrl = data
      ? `${window.location.protocol}//${
          process.env.REACT_APP_API_DOMAIN
        }/getApi.php?${Object.entries(data)
          .map(
            ([key, value]) => `${key}=${encodeURIComponent(value as string)}`
          )
          .join("&")}`
      : url;

    const response = await axios.post(
      `${window.location.protocol}//web.279rc.com/api/getApi.php`,
      {
        url: getUrl,
      }
    );

    if (response.data?.code == "-99") {
      window.dispatchEvent(new Event("timeout"));
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw new Error("Network error");
    }
  }
};

export const authGet = async (url: string, data?: any): Promise<any> => {
  try {
    const getUrl = `${window.location.protocol}//${
      process.env.REACT_APP_API_DOMAIN
    }/getApi.php?${Object.entries({
      ...data,
      agentid: getAuth()?.agentid,
      loginid: getAuth()?.loginid,
    })
      .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
      .join("&")}`;

    const response = await axios.post(
      `${window.location.protocol}//web.279rc.com/api/getApi.php`,
      {
        url: getUrl,
      }
    );

    if (response.data?.code == "-99") {
      window.dispatchEvent(new Event("timeout"));
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw new Error("Network error");
    }
  }
};
