import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { commissionSettingState } from "../models/state/CommissionSettingState";
import {
  getMemberCommissionPlanB,
  setupMemberCommissionPlanB,
} from "./CommissionSettingCRUD";

export const commissionSettingSlice = createSlice({
  name: "commissionSetting",
  initialState: commissionSettingState,
  reducers: {
    clearCommissionSettingList: (state) => {
      state.commissionSettingList =
        commissionSettingState.commissionSettingList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberCommissionPlanB.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(
        getMemberCommissionPlanB.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.commissionSettingList = action.payload;
        }
      )
      .addCase(
        getMemberCommissionPlanB.rejected,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.commissionSettingList =
            commissionSettingState.commissionSettingList;
        }
      )
      .addCase(setupMemberCommissionPlanB.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        setupMemberCommissionPlanB.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(
        setupMemberCommissionPlanB.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      );
  },
});
