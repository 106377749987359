import { IGeneralRedux } from "../../../common/models/response";
import {
  IDashboardBottomResponse,
  IDashboardHeaderResponse,
} from "../response";

export interface IHomeReduxState extends IGeneralRedux {
  dashboardHeader: IDashboardHeaderResponse | null;
  dashboardBottom: IDashboardBottomResponse | null;
}

export const homeState: IHomeReduxState = {
  dashboardHeader: null,
  dashboardBottom: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
