import { createContext, FC, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Auth } from "../../modules/auth/components/Auth";
import { AUTH_LOCAL_STORAGE_KEY } from "../../modules/common/redux/ApiClient";
import { Status404 } from "../../modules/statusPage/component/Status404";
export const AuthContext = createContext({});

export const AuthWrapper: FC<{ isClearAuth?: boolean }> = ({ isClearAuth }) => {
  const value = {};
  const { credential } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const [_, path, cred] = location.pathname.split("/");

    if (isClearAuth || (path === "auth" && cred)) {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
      setTimeout(
        () => navigate(`/${(isClearAuth ? credential : cred) ?? ""}`),
        100
      );
    }
  }, [isClearAuth]);

  return (
    <AuthContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Auth />} />
        <Route path=":credential" element={<Auth />} />
        <Route
          path="*"
          element={
            location.pathname.includes("auth") ? (
              <Navigate to="/" />
            ) : (
              <Status404 />
            )
          }
        />
      </Routes>
    </AuthContext.Provider>
  );
};
