import { IGeneralRedux } from "../response";
import { ICommonResponse } from "../response/CommonResponseModel";

export interface ICommonReduxState extends IGeneralRedux {
  agentBalance: ICommonResponse | null;
}

export const commonState: ICommonReduxState = {
  agentBalance: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
