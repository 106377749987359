import { Field, Form, Formik, FormikProps } from "formik";
import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getAuth } from "../../../../utils/AuthHelper";
import { Input } from "../../common/components/control/InputField";
import Modal from "../../common/components/Modal";
import { BaseResponseModel } from "../../common/models/response/ResponseModel";
import * as actions from "../redux/AgentAction";

export const AgentCreateModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAgents: () => void;
}> = ({ isModalOpen, setIsModalOpen, fetchAgents }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("info");

  const isPlanB = getAuth()?.plancode === "planb";

  const loginFormikRef = useRef<FormikProps<{
    username: string;
    password: string;
    confirmPassword: string;
    nickname: string;
    share: string;
    commissionmkt: string;
    commissionhl: string;
  }> | null>(null);

  let schemaObject = {
    username: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    share: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    nickname: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    password: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "passwordMustMatch" })
      )
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  };

  const loginSchema = Yup.object().shape(schemaObject);

  const planBSchema = Yup.object().shape({
    ...schemaObject,
    commissionmkt: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    commissionhl: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const createNewAgent = (values: {
    username: string;
    password: string;
    confirmPassword: string;
    nickname: string;
    share: string;
    commissionmkt: string;
    commissionhl: string;
  }) => {
    dispatch<any>(
      actions.createNewAgent({
        action: "createnewagentV2",
        nickname: values.nickname,
        username: values.username,
        password: values.password,
        share: values.share,
        commissionmkt: isPlanB ? values.commissionmkt : "0",
        commissionhl: isPlanB ? values.commissionhl : "0",
      })
    )
      .then((res?: BaseResponseModel) => {
        setIsResponseModalOpen(true);
        setResponseMessage(
          res?.code === "0"
            ? intl.formatMessage({ id: "createSuccessfully" })
            : res?.message
            ? res.message
            : intl.formatMessage({ id: "createFail" })
        );
        setModalTitle(res?.code === "0" ? "info" : "alert");
        if (res?.code === "0") {
          loginFormikRef.current?.resetForm();
          setIsModalOpen(false);
          fetchAgents();
        }
      })
      .catch(() => {
        setIsResponseModalOpen(true);
        setResponseMessage(intl.formatMessage({ id: "createFail" }));
        setModalTitle("alert");
      });
  };

  return (
    <>
      <Modal
        style={{ zIndex: "999999" }}
        isOpen={isResponseModalOpen}
        onClose={() => {
          setIsResponseModalOpen(false);
        }}
        title={intl.formatMessage({ id: modalTitle })}
      >
        {responseMessage}
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={intl.formatMessage({ id: "createAgent" })}
      >
        <Formik
          initialValues={{
            username: "",
            password: "",
            confirmPassword: "",
            nickname: "",
            share: "",
            commissionmkt: "",
            commissionhl: "",
          }}
          innerRef={loginFormikRef}
          validationSchema={isPlanB ? planBSchema : loginSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            console.log("zzz");
            createNewAgent(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="pt-4">
              <div className="mb-3">
                <Field
                  name="nickname"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "nickname",
                  })}
                  label={intl.formatMessage({
                    id: "nickname",
                  })}
                  autoComplete="off"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="username"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "username",
                  })}
                  label={intl.formatMessage({
                    id: "username",
                  })}
                  autoComplete="off"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="password"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "password",
                  })}
                  label={intl.formatMessage({
                    id: "password",
                  })}
                  autoComplete="off"
                  type="password"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="confirmPassword"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "confirmPassword",
                  })}
                  label={intl.formatMessage({
                    id: "confirmPassword",
                  })}
                  autoComplete="off"
                  type="password"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="share"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "share",
                  })}
                  label={intl.formatMessage({
                    id: "share",
                  })}
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text")
                      ?.replace(/\D/g, "");
                    setFieldValue("share", pastedText);
                  }}
                  autoComplete="off"
                  type="numberString"
                />
              </div>
              {isPlanB && (
                <>
                  <div className="mb-3">
                    <Field
                      name="commissionmkt"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "mktCommission",
                      })}
                      label={intl.formatMessage({
                        id: "mktCommission",
                      })}
                      onPaste={(e: any) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData
                          .getData("text")
                          ?.replace(/\D/g, "");
                        setFieldValue("commissionmkt", pastedText);
                      }}
                      autoComplete="off"
                      type="numberString"
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="commissionhl"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "hlCommission",
                      })}
                      label={intl.formatMessage({
                        id: "hlCommission",
                      })}
                      onPaste={(e: any) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData
                          .getData("text")
                          ?.replace(/\D/g, "");
                        setFieldValue("commissionhl", pastedText);
                      }}
                      autoComplete="off"
                      type="numberString"
                    />
                  </div>
                </>
              )}

              <button
                className="w-full px-4 py-2 mt-3 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                type="submit"
              >
                {intl.formatMessage({ id: "create" })}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
