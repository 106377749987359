import { IGeneralRedux } from "../../../common/models/response";
import { IReportResponse } from "../response";

export interface IReportReduxState extends IGeneralRedux {
  reports: IReportResponse | null;
}

export const reportState: IReportReduxState = {
  reports: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
