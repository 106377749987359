import { createAsyncThunk } from "@reduxjs/toolkit";
import { authPost } from "../../common/redux/ApiClient";
import { IReportRequest } from "../models/request";

export const getReport = createAsyncThunk(
  "sample/agentsummaryreport",
  async (data: IReportRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);
