import { AppDispatch } from "../../../../setup/Store";
import { IReportRequest } from "../models/request";
import * as requestApi from "./ReportCRUD";
import { reportSlice } from "./ReportSlice";

const { actions } = reportSlice;

export const clearReports = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearReports());
};

export const fetchReport =
  (data: IReportRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getReport(data));
  };
