import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseRequestModel } from "../../common/models/request/RequestModel";
import { authPost } from "../../common/redux/ApiClient";
import { IDashboardBottomRequest } from "../models/request";

export const getDashboardHeader = createAsyncThunk(
  "sample/dashboardheader",
  async (data: BaseRequestModel) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);

export const getDashboardBottom = createAsyncThunk(
  "sample/dashboardbottom",
  async (data: IDashboardBottomRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);
