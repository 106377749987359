export const isNullOrEmpty = (value: any): boolean => {
  if (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null &&
      Object.keys(value).length === 0)
  ) {
    return true;
  }

  return false;
};

export function defaultToEmptyArray<T>(array?: T[] | null): T[] {
  return array ?? [];
}
