import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reportState } from "../models/state/ReportState";
import { getReport } from "./ReportCRUD";

export const reportSlice = createSlice({
  name: "report",
  initialState: reportState,
  reducers: {
    clearReports: (state) => {
      state.reports = reportState.reports;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReport.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getReport.fulfilled, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.reports = action.payload;
      })
      .addCase(getReport.rejected, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.reports = reportState.reports;
      });
  },
});
