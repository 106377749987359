import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { commonState } from "../models/state/CommonState";
import { getAgentBalance } from "./CommonCRUD";

export const commonSlice = createSlice({
  name: "common",
  initialState: commonState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgentBalance.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(
        getAgentBalance.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.agentBalance = action.payload;
        }
      )
      .addCase(
        getAgentBalance.rejected,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.agentBalance = commonState.agentBalance;
        }
      );
  },
});
