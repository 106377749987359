import { useEffect, useState } from "react";
import { BsPersonUp } from "react-icons/bs";
import { CiEdit, CiShare2 } from "react-icons/ci";
import { IoMdPersonAdd } from "react-icons/io";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import { isNullOrEmpty } from "../../../../utils/ValidationHelper";
import { Loading, OverlayLoading } from "../../common/components/Loading";
import Pagination from "../../common/components/Pagination";
import { IMemberLists } from "../models/response";
import * as actions from "../redux/MemberAction";
import { ConvertMemberEditModal } from "./ConvertMemberEditModal";
import { MemberCreateModal } from "./MemberCreateModal";
import { MemberEditModal } from "./MemberEditModal";
import { MemberInfoModal } from "./MemberInfoModal";

export const Member = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreateMember, setIsCreateMember] = useState(false);
  const [memberData, setMemberData] = useState<IMemberLists | undefined>();
  const [agentData, setAgentData] = useState<IMemberLists | undefined>();

  const { memberList, actionsLoading, memberInfo } = useSelector(
    (state: RootState) => state.member
  );

  const handlePageChange = (page: number) => {
    fetchMembers(searchValue, page);
    setCurrentPage(page);
  };

  const fetchMembers = (searchValue: string, pagenum: number) => {
    dispatch<any>(
      actions.fetchMemberList({
        action: "memberlist",
        searchvalue: searchValue,
        pagenum: (pagenum - 1).toString(),
      })
    );
  };

  const shareMemberInfo = (memberid: string) => {
    dispatch<any>(
      actions.shareMemberInfo({
        action: "sharememberinfo",
        selmemberid: memberid,
      })
    );
  };

  useEffect(() => {
    fetchMembers(searchValue, currentPage);

    return () => {
      dispatch<any>(actions.clearMemberList());
      dispatch<any>(actions.clearMemberInfo());
    };
  }, []);

  useEffect(() => {
    if (memberInfo) {
      setModalOpen(true);
    }
  }, [memberInfo]);

  return (
    <>
      {actionsLoading && <OverlayLoading />}
      <MemberInfoModal
        setIsModalOpen={setModalOpen}
        isModalOpen={isModalOpen}
      />
      <MemberCreateModal
        isModalOpen={isCreateMember}
        setIsModalOpen={setIsCreateMember}
        fetchMembers={() => fetchMembers(searchValue, currentPage)}
      />
      <MemberEditModal
        setMemberData={setMemberData}
        memberData={memberData}
        fetchMembers={() => fetchMembers(searchValue, currentPage)}
      />
      <ConvertMemberEditModal
        setAgentData={setAgentData}
        agentData={agentData}
        fetchMembers={() => fetchMembers(searchValue, currentPage)}
      />
      <div className="flex-1">
        <div className="flex mb-4">
          <div className="flex flex-grow rounded bg-blue-950">
            <input
              type="text"
              placeholder={intl.formatMessage({ id: "search" })}
              className="flex-grow py-2 rounded outline-none ps-2 bg-blue-950 focus:outline-none"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button
              type="button"
              className="px-2 bg-blue-900 rounded"
              onClick={() => handlePageChange(1)}
            >
              {intl.formatMessage({ id: "search" })}
            </button>
          </div>
          <button
            type="button"
            className="px-2 font-semibold bg-yellow-400 rounded ms-2 whitespace-nowrap text-slate-900"
            onClick={() => setIsCreateMember(true)}
          >
            <IoMdPersonAdd className="inline-block size-5" />
          </button>
        </div>
        {memberList ? (
          memberList.memberLists?.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "nickname" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700 whitespace-nowrap">
                      {intl.formatMessage({ id: "inviteBy" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "action" })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {memberList.memberLists.map((member, index) => (
                    <tr key={member.memberid}>
                      <td className="p-2 text-sm border-b border-gray-700">
                        {(currentPage - 1) * 10 + (index + 1)}
                      </td>
                      <td className="p-2 text-sm border-b border-gray-700 whitespace-nowrap">
                        {!isNullOrEmpty(member.nickname)
                          ? member.nickname
                          : member.username}
                        <span
                          className={`ms-2 inline-block px-1.5 py-0.5 rounded text-xs font-medium ${
                            member.status === "active"
                              ? "bg-green-200 text-green-700"
                              : "bg-gray-200 text-gray-600"
                          }`}
                        >
                          {intl.formatMessage({
                            id:
                              member.status === "active"
                                ? "active"
                                : "inactive",
                          })}
                        </span>
                      </td>
                      <td className="p-2 text-sm border-b border-gray-700">
                        {member.inviteby}
                      </td>
                      <td className="p-2 text-sm border-b border-gray-700">
                        <div className="flex justify-center space-x-2">
                          <CiEdit
                            className="cursor-pointer size-4"
                            onClick={() => setMemberData(member)}
                          />
                          <CiShare2
                            className="cursor-pointer size-4"
                            onClick={() => shareMemberInfo(member.memberid)}
                          />
                          <BsPersonUp
                            className="cursor-pointer size-4"
                            onClick={() => setAgentData(member)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center">
              {intl.formatMessage({ id: "noRecordFound" })}
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
      <div className="flex items-center justify-center py-4">
        <Pagination
          totalPages={parseInt(memberList?.totalpage ?? "1")}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};
